<template>
  <van-pull-refresh
    v-model="isLoading"
    @refresh="onRefresh"
    style="height: 100%"
  >
    <div class="clock-in-container">
      <div class="company">{{ userInfo.rootDepartmentName }}</div>
      <template v-if="0 == clockSuccess">
        <div class="base-info">
          <img
            :src="userInfo.avatarUrl || defaultAvatar"
            alt=""
            class="avatar"
          />
          <div class="right">
            <div class="name">{{ userInfo.userName }}</div>
            <div class="department">
              <span class="departmentName">{{ userInfo.departmentName }}</span>
              <span class="go-clock-role" @click="goClockRole">(查看规则)</span>
            </div>
          </div>
        </div>

        <div class="clock-in-card">
          <div class="row1">
            <div class="on-work">
              <div class="need-time">上班{{ clock.beginTime }}</div>
              <div class="status" v-if="1 == clock.onWorkState">
                <van-icon name="success" class="success-icon" />
                <span>{{ clock.onWorkTime | formatTime }}已打卡</span>
              </div>
              <div class="status" v-else>未打卡</div>
            </div>
            <div class="off-work">
              <div class="need-time">下班{{ clock.endTime }}</div>
              <div class="status" v-if="1 == clock.afterWorkState">
                <van-icon name="success" class="success-icon" />
                {{ clock.afterWorkTime | formatTime }}已打卡
              </div>
              <div class="status" v-else>未打卡</div>
            </div>
          </div>
          <div class="main-button-container">
            <template v-if="0 == clockStatu">
              <div class="confirm-clock-in noClock">
                <div class="row1">无法打卡</div>
              </div>
              <p class="tip">未获取到位置坐标，无法打卡</p>
            </template>
            <template v-if="1 == clockStatu">
              <div class="confirm-clock-in inCircle" @click="clockInCheck">
                <div class="row1" v-if="'am' == amPm">上班打卡</div>
                <div class="row1" v-else>下班打卡</div>
                <div class="time">{{ clock.nowTime }}</div>
              </div>
              <p class="tip">
                <van-icon name="success" class="address-in-icon" />
                <span> 已进入考勤范围：{{ clock.address }}</span>
              </p>
            </template>
            <template v-if="2 == clockStatu">
              <div class="confirm-clock-in outCircle" @click="clockInCheck">
                <div class="row1">外勤打卡</div>
                <div class="time">{{ clock.nowTime }}</div>
              </div>
              <p class="tip">
                不在考勤范围，当前位置：{{ clock.localAddress }}
              </p>
            </template>
          </div>
        </div>
      </template>
      <div v-else class="success-container">
        <div class="header">
          <div class="row1">
            <div class="title">
              {{ "am" == amPm ? "上班" : "下班" }}打卡成功
            </div>
            <van-icon name="cross" class="cross" @click="clockSuccess = 0" />
          </div>
          <div class="row2">打卡时间{{ formatClockTime }}</div>
        </div>
        <div class="img-box">
          <img :src="successPng" alt="" class="img" />
        </div>

        <div class="bottom">
          <div class="go-static" @click="goStatistics">查看打卡结果</div>
        </div>
      </div>
    </div>
  </van-pull-refresh>
</template>

<script>
import { getMyStatffInfo } from "@/api/staff/index.js";
import { getSign } from "@/api/wechat";
import { clockInfo, handleClock } from "@/api/clock/index.js";
import { getWXOpenId } from "@/api/account";

import { Dialog } from "vant";
import Cookies from "js-cookie";
import defaultAvatar from "@/assets/images/home/header.png";
import successPng from "@/assets/images/clockIn/clockSuccess.png";
import isWeixin from "@/utils/isWeixin.js";

import { wgs84ToGcj02 } from "@/utils/wgs84ToGcj02.js";

export default {
  name: "clockIn",
  props: {},
  components: {},
  data() {
    return {
      userInfo: {}, //个人信息
      coordinate: {}, //位置信息

      wxConfig: false, //微信接口初始化

      clock: {}, //打卡信息
      loading: false,
      deviceId: "",
      defaultAvatar,
      getClock: null,
      isLoading: false,
      clockSuccess: 0,
      successPng,
    };
  },
  watch: {},
  async mounted() {
    plus.navigator.createShortcut(
      {
        name: "OA打卡",
        toast: "创建成功",
        force: false,
        uri: "/clockIn",
      },
      (event) => {
        console.log("event", event);
      }
    );

    await this.onOpen();
    this.getStaffInfo();
    this.getConfig();
    this.getAndroidConfig();
  },

  beforeDestroy() {
    clearInterval(this.getClock);
  },
  filters: {
    formatTime(val) {
      if (!val) {
        return "";
      }
      return val.split(" ")[1];
    },
  },
  computed: {
    clockStatu() {
      if (!this.coordinate.latitude) {
        return 0;
      }
      if (this.coordinate.latitude && 0 == this.clock.inCircle) {
        return 2;
      }
      if (this.coordinate.latitude && 1 == this.clock.inCircle) {
        return 1;
      }
    },
    amPm() {
      let nowTime = this.clock.nowTime;
      if (!nowTime) {
        return null;
      }
      let hour = Number(nowTime.split(":")[0]);
      if (12 > +hour) {
        return "am";
      } else {
        return "pm";
      }
    },
    formatClockTime() {
      let time;
      if ("am" == this.amPm) {
        time = this.clock.onWorkTime;
      } else {
        time = this.clock.afterWorkTime;
      }
      return time.split(" ")[1];
    },
    token() {
      if (
        !localStorage.getItem("Authorization_OA_H5") ||
        localStorage.getItem("Authorization_OA_H5") == "undefined" ||
        localStorage.getItem("Authorization_OA_H5") == undefined
      ) {
        return "";
      } else {
        return localStorage.getItem("Authorization_OA_H5");
      }
    },
  },
  methods: {
    getConfig() {
      if (!isWeixin()) {
        return;
      }

      // 进行签名的时候  Android 不用使用之前的链接， ios 需要
      let signLink = /(Android)/i.test(navigator.userAgent)
        ? location.href.split("#")[0]
        : window.entryUrl;

      // console.log("signLink", signLink);
      getSign({
        url: signLink,
      }).then((res) => {
        // 判断微信登录
        wx.config({
          debug: false, //测试时候用true 能看见wx.config的状态是否是config:ok
          appId: process.env.VUE_APP_WXAPPID, // 必填，公众号的唯一标识（公众号的APPid）
          timestamp: res.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
          signature: res.data.signature, // 必填，签名
          jsApiList: [
            "openLocation", // 使用微信内置地图查看位置接口
          ], // 必填，需要使用的JS接口列表
        });
        wx.error((res) => {
          console.error("error", res);
        });

        wx.checkJsApi({
          jsApiList: ["openLocation"], // 需要检测的 JS 接口列表，所有 JS 接口列表见附录2,
          success: (res) => {
            this.wxConfig = true;
            this.getClockInfo();
            this.getClock = setInterval(() => {
              this.getClockInfo();
            }, 1000);
          },
        });
        wx.ready(() => {});
      });
    },

    // 获取android配置
    getAndroidConfig() {
      // 判断Android-app登录
      if (!plus) {
        return;
      }
      this.getAndroidClockInfo();
      this.getClock = setInterval(() => {
        this.getAndroidClockInfo();
      }, 1000);
    },

    getStaffInfo() {
      getMyStatffInfo().then((res) => {
        this.userInfo = res.data;
      });
    },
    // 获取打卡信息
    getClockInfo() {
      if (!this.wxConfig) {
        return;
      }
      let openId = localStorage.getItem("openId_H5");
      if (openId == undefined || openId == "undefined") {
        openId = "";
      }
      // console.log("appid", process.env.VUE_APP_WXAPPID);

      let that = this;
      //如果微信初始化跟wx.getLocation相差时间很短，可适当延长延时时间
      setTimeout(() => {
        wx.getLocation({
          type: "gcj02", // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
          success: (res) => {
            that.coordinate = res;
            clockInfo({
              gcj02Lat: that.coordinate.latitude,
              gcj02Lon: that.coordinate.longitude,
              provideCode: openId,
            }).then((res) => {
              that.clock = res.data;
            });
          },
          fail: (e) => {
            console.error(e);
          },
        });
      }, 1000);
    },

    // android获取打卡信息
    getAndroidClockInfo() {
      plus.geolocation.getCurrentPosition(
        (p) => {
          let gcj = wgs84ToGcj02(p.coords.latitude, p.coords.longitude);

          // p为获取成功的定位数据
          this.coordinate = {
            latitude: gcj.latitude,
            longitude: gcj.longitude,
          };
          clockInfo({
            gcj02Lat: this.coordinate.latitude,
            gcj02Lon: this.coordinate.longitude,
          }).then((res) => {
            this.clock = res.data;
          });
        },
        (err) => {
          //失败回调
          console.error(
            "Gelocation Error: code - " +
              err.code +
              "; message - " +
              err.message
          );
        }
      );
    },

    clockInCheck() {
      if (1 == this.clock.onWorkState && "am" == this.amPm) {
        Dialog.confirm({
          title: "确认打卡",
          message: "已打过上班卡，是否更新打卡。",
        })
          .then(() => {
            this.clockIn();
          })
          .catch(() => {});
        return;
      }

      let nowTimeArr = this.clock.nowTime.split(":");
      let beginTimeArr = this.clock.beginTime.split(":");
      let endTimeArr = this.clock.endTime.split(":");

      let nowss = nowTimeArr[0] * 3600 + nowTimeArr[1] * 60 + nowTimeArr[2] * 1;
      let beginss = beginTimeArr[0] * 3600 + beginTimeArr[1] * 60 + 59;
      let endss = endTimeArr[0] * 3600 + endTimeArr[1] * 60;

      if ("am" == this.amPm && nowss > beginss) {
        Dialog.confirm({
          title: "迟到打卡",
          message: "迟到打卡，请确认打卡",
        })
          .then(() => {
            this.clockIn();
          })
          .catch(() => {});
        return;
      }
      if ("pm" == this.amPm && nowss < endss) {
        Dialog.confirm({
          title: "早退打卡",
          message: "早退打卡，请确认打卡",
        })
          .then(() => {
            this.clockIn();
          })
          .catch(() => {});
        return;
      }

      this.clockIn();
    },

    wxAuth() {
      const appid = process.env.VUE_APP_WXAPPID;
      const redirect_uri = encodeURIComponent(
        `${window.location.href}?latitude=${this.coordinate.latitude}&longitude=${this.coordinate.longitude}&localAddress=${this.clock.localAddress}`
      );
      const state = "";
      const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=${state}#wechat_redirect`;
      window.location.replace(url);
    },

    async onOpen() {
      let clockSuccess = this.$route.query.clockSuccess;
      if (clockSuccess) {
        this.clockSuccess = clockSuccess;
      }

      let openId = localStorage.getItem("openId_H5");
      if (openId) {
        return;
      }
      let code = this.$route.query.code;
      if (!code) {
        return;
      }
      const postData = {
        code: this.$route.query.code,
      };
      await getWXOpenId(postData).then((res) => {
        localStorage.setItem("openId_H5", res.data, {
          expires: 7,
        });
      });
      this.coordinate.latitude = this.$route.query.latitude;
      this.coordinate.longitude = this.$route.query.longitude;
      this.clock.localAddress = this.$route.query.localAddress;
      await this.clockIn();
      this.$router.replace({
        path: "/clockIn",
        query: {
          clockSuccess: 1,
        },
      });
    },

    async clockIn() {
      if (this.loading) {
        this.$toast("请勿重复提交");
        return;
      }
      this.$toast.loading({
        message: "打卡中...",
        forbidClick: true,
      });
      this.loading = true;
      let openId = localStorage.getItem("openId_H5");

      if (!openId && isWeixin()) {
        this.wxAuth();
        return;
      }

      let info = plus.push.getClientInfo();
      localStorage.setItem("openId_H5", info.clientid);

      // if (!openId && plus) {
      //   // 获取匿名设备标识符
      //   plus.device.getOAID({
      //     success: (e) => {
      //       console.log("getOAID success: " + JSON.stringify(e));
      //       localStorage.setItem("openId_H5", e.oaid);
      //       this.handleClockRequest();
      //     },
      //     fail: (e) => {
      //       console.log("getOAID failed: " + JSON.stringify(e));
      //       this.$toast("获取匿名设备号失败，打卡不成功");
      //       return;
      //     },
      //   });
      //   return;
      // }
      this.handleClockRequest();
    },

    // 发送打卡请求
    async handleClockRequest() {
      await handleClock({
        gcj02Lat: this.coordinate.latitude,
        gcj02Lon: this.coordinate.longitude,
        address: this.clock.localAddress,
        provideCode: localStorage.getItem("openId_H5"),
      })
        .then((res) => {
          this.$toast.clear();
          if (200 == res.code) {
            this.clockSuccess = 1;
            this.getClockInfo();
          } else {
            this.$toast("打卡失败请稍后重试");
          }
        })
        .catch((e) => {
          console.error(e);
          this.$toast("打卡失败请稍后重试");
        })
        .finally(() => {
          this.loading = false;
        });
    },

    goClockRole() {
      this.$router.push({
        path: "/clockRole",
      });
    },
    async onRefresh() {
      // this.$router.go(0);
      clearInterval(this.getClock);
      this.onOpen();
      if (this.token) {
        this.getStaffInfo();
        this.getConfig();
      }
      this.isLoading = false;
    },
    goStatistics() {
      this.$router.push({
        path: "/clockIn/statistics",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.clock-in-container {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background: #f3f6f6;
  padding-bottom: 1.2rem;
  .company {
    background: #fff;
    height: 0.9rem;
    font-size: 0.38rem;
    font-weight: bold;
    line-height: 0.9rem;
    padding: 0 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .base-info {
    width: 6.9rem;
    margin-left: 0.3rem;
    padding: 0 0.3rem;
    border-radius: 0.24rem;
    background: #fff;
    display: flex;
    height: 1.44rem;
    align-items: center;
    margin-top: 0.3rem;
    min-height: 1.44rem;
    .avatar {
      width: 0.8rem;
      height: 0.8rem;
      margin-right: 0.3rem;
      border-radius: 0.12rem;
    }
    .right {
      flex: 1;
      width: 1rem;
      .name {
        font-size: 0.34rem;
        margin-bottom: 0.18rem;
        font-size: 0.34rem;
      }
      .department {
        font-size: 0.28rem;
        color: #666;
        white-space: nowrap;
        display: flex;
        .departmentName {
          overflow: hidden;
          text-overflow: ellipsis;
          flex-shrink: 1;
        }
        .go-clock-role {
          color: #0094ee;
        }
      }
    }
  }
  .clock-in-card {
    padding: 0.3rem;
    margin-left: 0.3rem;
    width: 6.9rem;
    flex: 1;
    background: #fff;
    border-radius: 0.12rem;
    margin-top: 0.3rem;
    display: flex;
    flex-direction: column;
    .row1 {
      display: flex;
      .on-work,
      .off-work {
        border-radius: 0.12rem;
        padding-left: 0.22rem;
        height: 1.12rem;
        flex: 1;
        background: #ededed;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .need-time {
          font-size: 0.3rem;
          margin-bottom: 0.15rem;
        }
        .status {
          font-size: 0.24rem;
          color: #666;

          .success-icon {
            font-size: 0.2rem;
            color: #fff;
            background: #0094ee;
            margin-right: 0.1rem;
            border-radius: 1rem;
            width: 0.24rem;
            height: 0.24rem;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            vertical-align: middle;
            position: relative;
            top: -0.01rem;
          }
        }
      }

      .off-work {
        margin-left: 0.16rem;
      }
    }
    .main-button-container {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 0.24rem;
      min-height: 5rem;
      .confirm-clock-in {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 0.5rem;
        height: 2.8rem;
        width: 2.8rem;
        border-radius: 1.4rem;
        .row1 {
          font-size: 0.39rem;
          color: #fff;
          margin-bottom: 0.22rem;
        }
        .time {
          font-size: 0.24rem;
          color: rgba(255, 255, 255, 0.5);
        }
      }
      .confirm-clock-in.noClock {
        background: #999;
      }
      .confirm-clock-in.outCircle {
        background: #07c160;
      }
      .confirm-clock-in.inCircle {
        background: #0094ee;
      }

      .tip {
        font-size: 0.24rem;
        color: #666;
        .address-in-icon {
          font-size: 0.2rem;
          color: #fff;
          background: #07c160;
          margin-right: 0.1rem;
          border-radius: 1rem;
          width: 0.24rem;
          height: 0.24rem;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          vertical-align: middle;
          position: relative;
          top: -0.02rem;
        }
      }
    }
  }

  .success-container {
    flex: 1;
    background: #fff;
    margin-left: 0.3rem;
    width: 6.9rem;
    background: #fff;
    border-radius: 0.12rem;
    margin-top: 0.3rem;
    display: flex;
    flex-direction: column;
    min-height: 1.4rem;
    padding: 0.4rem;
    .header {
      width: 100%;
      .row1 {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        .title {
          font-size: 0.46rem;
          font-weight: bold;
        }
        .cross {
          font-size: 0.3rem;
          color: #666;
        }
      }
      .row2 {
        margin-top: 0.26rem;

        font-size: 0.25rem;
        color: #666;
      }
    }
    .img-box {
      flex: 1;
      align-items: center;
      justify-content: center;
      display: flex;
      .img {
        width: 4rem;
        height: 2.3rem;
      }
    }

    .bottom {
      display: flex;
      align-items: center;
      justify-content: center;
      .go-static {
        font-size: 0.32rem;
        color: #0094ee;
      }
    }
  }
}
</style>
